// Bill Payment api
export const timbillPaymentBaseUrl = '/report/tim/'
export const billPaymentReport = timbillPaymentBaseUrl + 'payment-report'
export const wordExportAllowanceReport = timbillPaymentBaseUrl + 'allowance-report-word'
// Other Bill Payment api
export const timotherbillPaymentBaseUrl = '/report/other-tim/'
export const otherBillPaymentReport = timotherbillPaymentBaseUrl + 'payment-report'
export const otherMaterialReportData = timbillPaymentBaseUrl + 'other-material-report'
//
export const prePostEvaluationCompareReport = '/report/pre-post-evaluation-compare-report'
export const dormitoryOtherChargeReport = '/report/dormitory-others-charge-report'
export const trainingCourseSummaryReport = '/report/training-course-summary-report'
export const trainingCourseAttendenceReport = '/report/training-course-wise-attendence-report'
export const trainingCourseWiseParticipantReport = '/report/training-course-wise-participant-report'
export const performanceReport = '/report/performance-report'
export const traineePerformanceReport = '/report/trainee-performance-report'
export const trainerPerformanceReport = '/report/trainer-performance-report'
export const trainingSummryReport = '/report/training-summary-report'
export const dormitoryOthersChargeReport = '/report/dormitory-others-charge-report'
export const attendanceBaseUrl = '/tim/attendances/'
export const attendanceStore = attendanceBaseUrl + 'store'
export const circularWiseAttendanceList = '/report/circular-wise_attendance'
export const circularWiseAttendanceApplicationList = '/report/application-list'
export const trainingScheduleBaseUrl = '/tpm/training-schedule/'
export const trainingModuleWiseTrainer = trainingScheduleBaseUrl + 'module-wise-trainer'
export const trainingApplicationBaseUrl = '/iabm/training-applications/'
export const trainingApplicationAllList = trainingApplicationBaseUrl + 'application-list'
export const attendenceSheetBaseUrl = '/tpm/training-attendence/'
export const attendenceSheetList = attendenceSheetBaseUrl + 'list'
// Training Course Evaluation Report
export const manualCourseEvaluationBaseUrl = '/tim/manual-course-evaluation/'
export const manualCourseEvaluation = manualCourseEvaluationBaseUrl + 'manual-course-evaluation'
export const circularPublication = '/tpm/training-calendar/circular-publication'
export const trainingCalendarBaseUrl = '/tpm/training-calendar/'
export const circularPublicationList = trainingCalendarBaseUrl + 'circular-publication-list'
export const otherMaterialReport = '/tim/bill-payment/honorarum-list-details'
// Venue Reservation Report
export const venueReservationReport = '/report/venue-reservation-report'

// Filter Circular List
export const circularPublicationBaseUrl = '/iabm/circular-publications/'
export const circularList = circularPublicationBaseUrl + 'circular-publication-list'

// Personal Info List
export const personalInfoBaseUrl = '/iabm/personal-infos/'
export const personalDataList = personalInfoBaseUrl + 'personal-info-list'
